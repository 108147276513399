import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../../layout/layout"
import HighlightSeries from "../../components/common/highlightsSeries"
import Seo from "../../components/seo/seo"
import { useTranslation } from "react-i18next"
import Img from 'gatsby-image'
import SlideInRight from '../../components/utility/slideInRightAnimation'
import { JsonLd } from "../../components/seo/jsonld"
import Link from "../../utilities/link"
import Modal from "../../components/common/modal"
import LaundryNews from "../../components/forms/laundryNewsForm"
import ExitIntent from "../../utilities/exitIntent"

const HeraSmartOPL = ({ data }) => {

  const { t } = useTranslation();
  // const [elcIsVisible, setShowElc] = useState(1);
  // const [caIsVisible, setShowCa] = useState(0);
  // const [paIsVisible, setShowPa] = useState(0);
  const [activeBenIndex, setActiveBenIndex] = useState(0);

  // function showElc() {
  //   setShowElc(1);
  //   setShowCa(0);
  //   setShowPa(0);
  // }

  // function showCa() {
  //   setShowElc(0);
  //   setShowCa(1);
  //   setShowPa(0);
  // }

  // function showPa() {
  //   setShowElc(0);
  //   setShowCa(0);
  //   setShowPa(1);
  // }

  function updateIndex(currentBen) {
    setActiveBenIndex(t('herasmartbenefits', { returnObjects: true }).findIndex((element, index) => {
      if (element.title === currentBen.title) {
        return true
      }
    }));
  }

  // gestione popup modal

  const [showModal, setShowModal] = useState(false);

  // caricamento dopo 10 secondi

  useEffect(() => {
    // dopo 10 secondi dal caricamento della pagina, compare il popup
    const timer = setTimeout(() => setShowModal(true), 10000);
    //poi il timer si resetta
    return () => clearTimeout(timer);
  }, []);

  // caricamento on exit (con cookie per evitare che si ripresenti il popup fino al giorno successivo)

  useEffect(() => {
    const removeExitIntent = ExitIntent({
      threshold: 30,
      eventThrottle: 100,
      onExitIntent: () => {
        if (document.cookie.indexOf("modal_seen=true") < 0) {
          setShowModal(true)
          let expiryDate = new Date(
            Date.now() + 1 * (1000 * 60 * 60 * 24)
          )
          expiryDate.setFullYear(expiryDate.getFullYear() + 1)
          document.cookie =
            "modal_seen" + "=true; expires=" + expiryDate.toUTCString()
          }
      },
    })
    return () => {
      removeExitIntent()
    }
  })

  return (

  <Layout>
    { showModal 
      ? 
      <Modal
        isActive = "is-active"
        component={<LaundryNews />}
        onCloseClick={() => setShowModal(false)}
      /> 
      : 
      null
    }
    <Seo
      title={t("herasmart.seoTitle")}
      description={t("herasmart.seoDescription")}
      seoImage={data.hera_kit.childImageSharp.resize.src}
    />
    <JsonLd>
      {{
        "@context": "https://schema.org/",
        "@type": "Product",
        "name": "Hera Laundry Smart",
        "image": [
          "https://example.com/photos/1x1/photo.jpg",
          "https://example.com/photos/4x3/photo.jpg",
          "https://example.com/photos/16x9/photo.jpg"
         ],
        "description": "Electronic control for industrial washing machines and laundry equipment, with user inteface, input-output board and internet connection. It comes with a dedicated mobile application for wash programs configuration and a dedicated cloud-based dashboard for monitoring productivity, costs and consumptions in a central industrial laundry.",
        "sku": "CTAHERASMART",
        "mpn": "CTA9795025",
        "brand": {
          "@type": "Brand",
          "name": "CTA Electronics Srl"
        },
        "review": {
          "@type": "Review",
          "reviewRating": {
            "@type": "Rating",
            "ratingValue": "5",
            "bestRating": "5"
          },
          "author": {
            "@type": "Person",
            "name": "G. Monti"
          }
        },
      }}
    </JsonLd>
    {/* Sezione Header */}
    <div className="section is-medium is-relative has-light-background" style={{display: 'inline-block', width: '100%', paddingBottom: '100px',overflow: 'hidden', verticalAlign: 'middle'}}>
      <svg width="100vw" height="140vh" viewBox="0 0 100vw 140vh" style={{position: "absolute", display: 'inline-block', top: "0", left: 0, fillRule: "evenodd", clipRule: "evenodd", strokeLinecap:"round", strokeLinejoin:"round"}} xmlns="http://www.w3.org/2000/svg" space="preserve" >
        <defs>
        <linearGradient y1="364.683" id="LinearGradient" x1="-2.95224" y2="364.683" x2="2005.71" gradientUnits="userSpaceOnUse" gradientTransform="matrix(1 0 0 1 0 0)">
        <stop stop-color="#7dd1a7" offset="0"/>
        <stop stop-color="#71bdf4" offset="1"/>
        </linearGradient>
        </defs>
        <g id="Livello 1" layerName="Livello 1">
        <path d="M-1.43513+0.575764L2002.67+0.575764L2000+381.632C2000+381.632+1827.32+319.668+1447.66+379.973C1068.01+440.279+830.684+620.543+474.162+617.13C109.78+613.64-2.95224+456.261-2.95224+456.261L-1.43513+0.575764Z" opacity="1" fill="url(#LinearGradient)"/>
        </g>
      </svg>
      <div className="container">
        <div className="columns is-vcentered">
          <div className="column is-6">
            <div className="content is-large">
              <h1 className="is-white is-bottom-bordered-white">{t('herasmart.pageTitle')}</h1>
              <h2 className="title is-4" style={{marginTop: 0}}>{t('herasmart.pageSubtitle')}</h2>
            </div>
          </div>
          <div className="column is-6 has-text-centered">
            <Img fluid={data.hera_kit.childImageSharp.fluid} alt={t('herasmart.altImageHeraKit')} />
          </div>
        </div>
      </div>
    </div>

    
    {/* Sezione Mission */}
      <HighlightSeries
        //illustration={<SvgHeraLaundry />}
        //fluid={data.textile_laundry.childImageSharp.fluid}
        mainTitle={t('herasmart.missionTitle')}
        titleColour=""
        isGradient="has-light-background"
        title={t('herasmart.missionSubtitle')}
      />
     
    {/* Sezione Dettagli Prodotto*/}
    <div className="section is-medium">
      <div className="container">
        <div className="columns is-vcentered">
          <div className="column is-6">
            <div className="content is-large">
              <h2 className="title is-3" style={{paddingBottom: '25px', borderBottom: '3px solid #7dd1a7'}}>{t('herasmart.p1Title')}</h2>
            </div>
            <div className="content">            
              <p>{t('herasmart.p1Text')}</p>
                <ul>
                  <li>{t('herasmart.p1Li1')}</li>
                  <li>{t('herasmart.p1Li2')}</li>
                  <li>{t('herasmart.p1Li3')}</li>
                  <li>{t('herasmart.p1Li4')}</li>
                </ul>
            </div>
          </div>
          <div className="column is-6">
            <Img fluid={data.cpu_hera.childImageSharp.fluid} alt={t('herasmart.altImageCPU')} />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="columns is-vcentered" style={{paddingTop: '75px'}}>
          <div className="column is-6">
            <div className="content is-large">
              <h2 className="title is-3" style={{paddingBottom: '25px', borderBottom: '3px solid #7dd1a7'}}>{t('herasmart.p2Title')}</h2>
            </div>
            <div className="content">            
                <ul>
                  <li>{t('herasmart.p2Li1')}</li>
                  <li>{t('herasmart.p2Li2')}</li>
                  <li>{t('herasmart.p2Li3')}</li>
                  <li>{t('herasmart.p2Li4')}</li>
                  <li>{t('herasmart.p2Li5')}</li>
                  <li>{t('herasmart.p2Li6')}</li>
                  <li>{t('herasmart.p2Li7')}</li>
                  <li>{t('herasmart.p2Li8')}</li>
                </ul>
            </div>
          </div>
          <div className="column is-6">
            <Img fluid={data.io_hera.childImageSharp.fluid} alt={t('herasmart.altImageIO')} />
          </div>
        </div>
      </div>



      <div className="container">
        <div className={`columns is-vcentered`} style={{paddingTop: '75px'}}>
          <div className="column is-6">
            <div className="content is-large">
              <h2 className="title is-3" style={{paddingBottom: '25px', borderBottom: '3px solid #7dd1a7'}}>{t('herasmart.p3Title')}</h2>
            </div>
            <div className="content">            
              <p>{t('herasmart.p3Text')}</p>
                <ul>
                  <li>{t('herasmart.p3Li1')}</li>
                  <li>{t('herasmart.p3Li2')}</li>
                  <li>{t('herasmart.p3Li3')}</li>
                  <li>{t('herasmart.p3Li4')}</li>
                  <li>{t('herasmart.p3Li5')}</li>
                </ul>
            </div>
            <Link to="/smart-dashboard/" title={t('header.HeraSmartDashBoardTitleTag')} className="button is-medium is-outlined is-primary" style={{marginTop: '25px'}}><strong>{t('herasmart.p3Button')}</strong></Link>
          </div>
          <div className="column is-6">
            <Img fluid={data.consumi.childImageSharp.fluid} alt={t('herasmart.altImageConsumptions')} />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="columns is-vcentered" style={{paddingTop: '75px'}}>
          <div className="column is-6">
            <div className="content is-large">
              <h2 className="title is-3" style={{paddingBottom: '25px', borderBottom: '3px solid #7dd1a7'}}>{t('herasmart.p4Title')}</h2>
            </div>
            <div className="content">            
            <ul>
                  <li>{t('herasmart.p4Li1')}</li>
                  <li>{t('herasmart.p4Li2')}</li>
                  <li>{t('herasmart.p4Li3')}</li>
                  <li>{t('herasmart.p4Li4')}</li>
                  <li>{t('herasmart.p4Li5')}</li>
                  <li>{t('herasmart.p4Li6')}</li>
                  <li>{t('herasmart.p4Li7')}</li>
                  <li>{t('herasmart.p4Li8')}</li>
                </ul>
            </div>
            <Link to="/configuratore-lavaggi/" title={t('header.HeraWashConfiguratorTitleTag')} className="button is-medium is-outlined is-primary" style={{marginTop: '25px'}}><strong>{t('herasmart.p4Button')}</strong></Link>
          </div>
          <div className="column is-6">
            <Img fluid={data.app.childImageSharp.fluid} alt={t('herasmart.altImageWashConfigurator')} />
          </div>
        </div>
      </div>
    </div>
    <div className="section">
      <div className="columns">
      <div className="column is-6 has-text-right" style={{paddingTop: '75px'}}>
            <Link to="/contatti" className="button is-medium is-outlined is-info"><strong>{t('herabasic.overviewButton1')}</strong></Link>
          </div>
          <div className="column is-6 has-text-left" style={{paddingTop: '75px'}}>
            <Link to="/contatti" className="button is-medium is-outlined is-primary"><strong>{t('herabasic.overviewButton2')}</strong></Link>
          </div>
      </div>
    </div>
     
     {/* Sezione testimonial e referenze */}
     <div className="section is-medium has-light-background">
      <div className="container">
        <div className="columns">
          <div className="column is-4">
            <div className="content">
              <h2 className="title is-2 is-bottom-bordered">{t('herasmart.benefitsTitle')}</h2>
              <p>{t('herasmart.benefitsSubtitle')}</p>
            </div>
          </div>
          <div className="column is-1"></div>
          <div className="column is-7">
          {t('herasmartbenefits', { returnObjects: true }).map((ben) => activeBenIndex === t('herasmartbenefits', { returnObjects: true }).findIndex((element, index) => {
            if (element.title === ben.title) {
              return true
            }
          }) ?
            <SlideInRight>
            <div className={`content`} key={ben.title}>
              <h4 className="title is-3 is-bottom-bordered">{ben.title}</h4>
              <p className="subtitle is-5">{ben.text}</p>
            </div>
            </SlideInRight>
            : ""
          )}
          <div className="container is-flex is-centered" style={{paddingTop: '75px'}}>
          {t('herasmartbenefits', { returnObjects: true }).map((ben) => activeBenIndex === t('herasmartbenefits', { returnObjects: true }).findIndex((element, index) => {
            if (element.title === ben.title) {
              return true
            }
          }) ?
        <span role="button" onClick={() => updateIndex(ben)}>
         <svg xmlns="http://www.w3.org/2000/svg" style={{width: '50px', height: '50px'}} fill="none" viewBox="0 0 24 24" stroke={`#2196f3`}>
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
        </svg>
        </span>
        :
        <span role="button" onClick={() => updateIndex(ben)}>
         <svg xmlns="http://www.w3.org/2000/svg" style={{width: '50px', height: '50px'}} fill="none" viewBox="0 0 24 24" stroke={`currentColor`}>
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
         </svg>
        </span>
        
        )}
          </div>
          </div>
        </div>
          
      </div>
    </div>
  

  
    
  </Layout>
  )
}

export default HeraSmartOPL

export const Home = graphql`
  query {
    app: file(
      relativePath: { eq: "Hera_laundry_mobile_app.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cpu_hera: file(
      relativePath: { eq: "Hera_Controllo_Elettronico_Per_Lavatrici.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    io_hera: file(
      relativePath: { eq: "Relays_Board_Hera_Laundry_Equipment.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hera_kit: file(
      relativePath: { eq: "Hera_Smart_Electronics_for_Washing_Machines.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
        resize(width: 900, quality: 90) {
          src
        }
      }
    }
    consumi: file(
      relativePath: { eq: "Industrial_Laundry_Costs_Consumptions.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
